/* eslint-disable func-names */
/**
 * Apply page padding to offset the fixed menu.
 */

const applyPagePadding = () => {
  const page = document.querySelector(".page--offset");

  if (!page) {
    return;
  }

  const menu = document.querySelector(".header");

  const menuHeight = menu.offsetHeight;

  const style = `${menuHeight}px`;

  page.style.paddingTop = style;
};

// Bind load and resize events to reapply page padding.
document.addEventListener("DOMContentLoaded", applyPagePadding);
window.addEventListener("resize", applyPagePadding);

// Menu.
const menu = () => {
  const icon = document.querySelector(".toggle");
  const navigation = document.querySelector(".navigation");
  const underlay = document.querySelector(".underlay");
  const page = document.querySelector(".page");

  const toggle = () => {
    icon.classList.toggle("toggle--open");
    navigation.classList.toggle("navigation--open");
    underlay.classList.toggle("underlay--active");
    page.classList.toggle("page--locked");
    // eslint-disable-next-line eqeqeq
    if (icon.classList == "toggle") {
      // eslint-disable-next-line no-undef
      bodyScrollLock.enableBodyScroll(navigation);
    } else {
      // eslint-disable-next-line no-undef
      bodyScrollLock.disableBodyScroll(navigation);
    }
  };

  icon.addEventListener("click", toggle);
  underlay.addEventListener("click", toggle);
};

menu();

class Modal {
  constructor(element) {
    this.modal = document.querySelector(element);
  }

  open(data) {
    this.modal.classList.remove("team-modal--close");
    this.modal.classList.add("team-modal--open");
    const name = document.querySelector(".team-modal__name");
    name.textContent = data.name;
    const role = document.querySelector(".team-modal__role");
    role.textContent = data.role;
    const bio = document.querySelector(".team-modal__text");
    bio.textContent = data.bio;
    // eslint-disable-next-line no-undef
    bodyScrollLock.disableBodyScroll(
      document.querySelector(".team-modal__wrap")
    );
  }

  close() {
    this.modal.classList.remove("team-modal--open");
    this.modal.classList.add("team-modal--close");
    // eslint-disable-next-line no-undef
    bodyScrollLock.enableBodyScroll(
      document.querySelector(".team-modal__wrap")
    );
  }
}

const modal = new Modal(".team-modal");

const closeModalButton = document.querySelector(".team-modal__exit");

const modalElement = document.querySelector(".team-modal");

if (modalElement) {
  modalElement.addEventListener("click", event => {
    if (
      event.target.classList.contains("team-modal__exit") ||
      event.target.classList.contains("team-modal") ||
      event.target.classList.contains("team-modal__icon")
    ) {
      event.preventDefault();
      modal.close();
    }
  });
  closeModalButton.addEventListener("click", event => {
    console.log("click");
    event.preventDefault();
    modal.close();
  });
}

class Modal2 {
  constructor(element) {
    this.modal = document.querySelector(element);
  }

  open(data) {
    this.modal.classList.remove("modal--close");
    this.modal.classList.add("modal--open");
    const name = document.querySelector(".modal__name");
    name.textContent = data.name;
    const role = document.querySelector(".modal__role");
    role.textContent = data.role;
    const bio = document.querySelector(".modal__text");
    bio.textContent = data.bio;
  }

  close() {
    this.modal.classList.remove("modal--open");
    this.modal.classList.add("modal--close");
    bodyScrollLock.enableBodyScroll(document.querySelector(".modal__wrap"));
  }
}

const modal2 = new Modal2(".modal");

const closeModalButton2 = document.querySelector(".modal__exit");

const modalElement2 = document.querySelector(".modal");

if (modalElement2) {
  const modals = document.querySelectorAll(".modal");

  modals.forEach(modal => {
    modal.addEventListener("click", event => {
      if (
        event.target.classList.contains("modal__exit") ||
        event.target.classList.contains("modal") ||
        event.target.classList.contains("modal__icon")
      ) {
        event.preventDefault();
        modal.classList.remove("modal--open");
        modal.classList.add("modal--close");
        bodyScrollLock.enableBodyScroll(document.querySelector(".modal__wrap"));
      }
    });
  });
  modalElement2.addEventListener("click", event => {
    if (
      event.target.classList.contains("modal__exit") ||
      event.target.classList.contains("modal") ||
      event.target.classList.contains("modal__icon")
    ) {
      event.preventDefault();
      modal2.close();
    }
  });
  closeModalButton2.addEventListener("click", event => {
    event.preventDefault();
    modal2.close();
  });
}

const careers = document.querySelector(".careers");

if (careers) {
  careers.addEventListener("click", event => {
    const classes = event.target.classList;

    if (classes.contains("career__link")) {
      const target = event.target.getAttribute("data-modal");

      if (!target) {
        return;
      }

      const modal = document.querySelector(`.${target}`);

      modal.classList.remove("modal--close");
      modal.classList.add("modal--open");
      bodyScrollLock.disableBodyScroll(document.querySelector(".modal__wrap"));
    }
  });
}

const teamSlider = () => {
  /* eslint-disable no-undef */
  $(".team__slider").slick({
    dots: false,
    arrows: true,
    prevArrow: $(".prev"),
    nextArrow: $(".next"),
    infinite: true,
    centerMode: true,
    speed: 300,
    autoPlay: true,

    slidesToShow: 1,
    slidesToScroll: 1,
  });

  $(".slick-center")
    .first()
    .addClass("show-team");
  $(".slick-center")
    .prev()
    .addClass("show-team");
  $(".slick-center")
    .next()
    .addClass("show-team");

  $(".team__slider").on("afterChange", () => {
    $(".slick-slide").removeClass("show-team");
    $(".slick-center")
      .first()
      .addClass("show-team");
    $(".slick-center")
      .prev()
      .addClass("show-team");
    $(".slick-center")
      .next()
      .addClass("show-team");
  });
  /* eslint-enable no-undef */

  // const slider = document.querySelector(".team__slider");

  // if (slider) {
  //   slider.addEventListener("click", event => {
  //     if (!event.target.classList.contains("team__member")) {
  //       return;
  //     }
  //     const data = {
  //       name: event.target.getAttribute("data-name"),
  //       role: event.target.getAttribute("data-role"),
  //       bio: event.target.getAttribute("data-bio")
  //     };

  //     modal.open(data);
  //   });
  // }
};

teamSlider();

const caseSlider = () => {
  /* eslint-disable no-undef */
  $(".case__slider").slick({
    dots: false,
    arrows: true,
    //prevArrow: $(".prev"),
    //nextArrow: $(".next"),
    infinite: true,
    speed: 300,
    autoPlay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1248,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $(".slick-center")
    .first()
    .addClass("show-team");
  $(".slick-center")
    .prev()
    .addClass("show-team");
  $(".slick-center")
    .next()
    .addClass("show-team");

  $(".case__slider").on("afterChange", () => {
    $(".slick-slide").removeClass("show-team");
    $(".slick-center")
      .first()
      .addClass("show-team");
    $(".slick-center")
      .prev()
      .addClass("show-team");
    $(".slick-center")
      .next()
      .addClass("show-team");
  });
};

caseSlider();

const faqs = () => {
    /* Handle the topic in the dropdown being changed */
    $(".researcher-faqs__dropdown").change(function() {
        const target = $(this).val();
        if (!target) return;

        $(".researcher-faqs .accordion").hide()
        $(`.researcher-faqs .accordion[data-topic=${target}]`).show();
    });

    /* Handle opening/closing an accordion item */
    $(".researcher-faqs .accordion-item__header").click(function() {
        const accordionBodies = $(".researcher-faqs .accordion-item__body");
        accordionBodies.slideUp();
        $(this).removeClass("open");

        if ($(this).next().is(":hidden")) {
            $(this).next().slideToggle();
            $(this).toggleClass("open");
        } else {
            accordionBodies.slideUp();
            $(this).removeClass("open");
        }
    });

    // Make sure accordion items start closed on page load
    $(".researcher-faqs .accordion-item__body").slideUp();
};

faqs();

// form consent button.
$("#consent").on("change", function() {
  document.querySelector(".form-consent-button").toggleAttribute("disabled");
});

new WOW().init();